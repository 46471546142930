import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
    // const se = "sqp_2c591f6e457e8691a1e19e43cb17414382f5aefd";

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Edit <code>src/App.tsx</code> and save to reload.
                </p>                
            </header>
        </div>
    );
}

export default App;
